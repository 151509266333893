interface UpsellLinksTypes {
  en?: string;
  es?: string;
  de?: string;
  fr?: string;
  it?: string;
}

export const UpsellsLinks: Record<string, UpsellLinksTypes> = {
  meal_planning: {
    en: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarnivore_meal_planning_on_a_budget.pdf?alt=media&token=c1267e38-5945-4347-aa6c-312646ad4a33',
    es: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarnivore_meal_planning_on_a_budget_es.pdf?alt=media&token=7cea485f-474e-422c-b5f7-8c7ebe2e3913',
    de: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarnivore_meal_planning_on_a_budget_de.pdf?alt=media&token=44a87813-2c80-42a9-9888-27b4195177ba',
    fr: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarnivore_meal_planning_on_a_budget_fr.pdf?alt=media&token=255e0378-d53c-4b87-ae83-2df13e56f72b',
    it: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarnivore_meal_planning_on_a_budget_it.pdf?alt=media&token=e342be55-aff5-4d0f-9f75-4760550d97be',
  },
  cortisol_detox: {
    en: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarni_cortisol.pdf?alt=media&token=02977ba6-dd7a-4807-b267-9d8a433ca915',
    es: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarni_cortisol_es.pdf?alt=media&token=ba65742d-2465-488e-898c-b6a46908908b',
    de: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarni_cortisol_de.pdf?alt=media&token=11e2bc1f-878a-4f67-aa9f-a541b1c75f07',
    fr: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarni_cortisol_fr.pdf?alt=media&token=6b5c9e31-fe8d-4b26-a13e-9414430f9f4e',
    it: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarni_cortisol_it.pdf?alt=media&token=f7c8525c-163e-4928-9f15-b13ec9ba68bf',
  },
  fasting: {
    en: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Ffasting_made_easy.pdf?alt=media&token=40116224-9908-4e69-b1a2-e8bba351a184',
    es: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Ffasting_made_easy_es.pdf?alt=media&token=db6726d1-7246-43fd-a5c9-e33d88afbd77',
    de: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Ffasting_made_easy_de.pdf?alt=media&token=1aea9e1a-f064-4f60-af9d-8b7586719ad8',
    fr: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Ffasting_made_easy_fr.pdf?alt=media&token=d857e994-5209-47f9-bc0e-9704b79a3d05',
    it: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Ffasting_made_easy_it.pdf?alt=media&token=4f326fbe-14dd-4019-9b82-d27c73b4f9c1',
  },
  get_fitter: {
    en: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fhealth_habits.pdf?alt=media&token=16d922a1-d00e-490d-bf07-de01673e3374',
    es: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fhealth_habits_es.pdf?alt=media&token=9631096d-7763-4853-a952-873e4197a520',
    de: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fhealth_habits_de.pdf?alt=media&token=fb7adaa9-6d76-4624-bb55-d7b03eab4ac5',
    fr: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fhealth_habits_fr.pdf?alt=media&token=830664a7-03fe-4695-af44-b15ee1ebb7c3',
    it: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fhealth_habits_it.pdf?alt=media&token=ae5e4785-707b-4cae-a9d3-b9db051bc1b1',
  },
  maximizing_gains: {
    en: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmaximizing_your_gains.pdf?alt=media&token=d3ae35fa-d704-4566-9206-32f2f0ec1c54',
    es: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmaximizing_your_gains_es.pdf?alt=media&token=0bfa1f1d-5322-4d53-b508-39a0ed35177f',
    de: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmaximizing_your_gains_de.pdf?alt=media&token=56a345f7-1b93-4d59-b77c-77cf1cca88fd',
    fr: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmaximizing_your_gains_fr.pdf?alt=media&token=ee8b064d-468f-4ea7-92a3-f90ce18f09e7',
    it: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmaximizing_your_gains_it.pdf?alt=media&token=cc0606bf-34c0-4f74-ba3e-dd1d8bba0d36',
  },
  menopause: {
    en: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarni_menopause.pdf?alt=media&token=531b0a02-90ba-4f50-a103-846d9cc92920',
    es: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarni_menopause_es.pdf?alt=media&token=885b43c8-a5a4-411a-a2cf-270cdeb5c5d4',
    de: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarni_menopause_de.pdf?alt=media&token=6596a819-8e53-40df-aeea-2dbb16132e78',
    fr: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarni_menopause_fr.pdf?alt=media&token=9f758653-ce61-434d-8ec2-11c904ea7838',
    it: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fcarni_menopause_it.pdf?alt=media&token=31d9e4ec-7b56-4aa3-aa09-e48410ecd73d',
  },
  menstrual: {
    en: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmenstrual_cycle_based_diet_plan.pdf?alt=media&token=34cbf67c-bce5-4226-b0de-ec9ab974e987',
    es: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmenstrual_cycle_based_diet_plan_es.pdf?alt=media&token=7b4e2ec9-1f38-4b89-bde4-45e163966754',
    de: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmenstrual_cycle_based_diet_plan_de.pdf?alt=media&token=afff36d4-26d6-4550-9476-18d32765b10d',
    fr: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmenstrual_cycle_based_diet_plan_fr.pdf?alt=media&token=a29afd31-ce11-48ba-881c-145e751eb1e9',
    it: 'https://firebasestorage.googleapis.com/v0/b/carnimeat-app-prod.firebasestorage.app/o/upsells%2Fmenstrual_cycle_based_diet_plan_it.pdf?alt=media&token=ead8cb35-31cd-48f4-87a6-1929f51d928b',
  },
};
