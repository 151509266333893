import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

import { LoadingPage, Seo } from 'components';
import { AppState } from 'state/types';
import HowToSection from './components/HowToSection';
import ThankYouSection from './components/ThankYouSection';
import OrderSummary from './components/OrderSummary';
import Header from 'components/Header';
import { usePageView, useQuizData } from 'utils/hooks';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';
import MWSection from './components/MWSection';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const MWContainer = styled.div`
  width: 100%;
`;

const Success: FC<RouteComponentProps> = () => {
  const code = useSelector((state: AppState) => state.user.code);
  const data = useQuizData('success');
  const { upsell_products, geolocation, quiz_answers, user } = useSelector(
    (state: AppState) => state.user,
  );
  const includesCrossSell = upsell_products?.some(item =>
    item.key.includes('mindway'),
  );

  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    client_code: code,
  });

  useEffect(() => {
    if (!code) {
      return;
    }

    Tracking.logEvent({
      event: 'email_left',
      client_code: code,
    });
  }, [code]);

  const MWRef = useRef(null);

  const scrollToMW = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    MWRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header logoVariant="center" hasDivider sticky={false} />
      <Container>
        <ThankYouSection {...data?.section1} />
        {/* <HowToSection {...data?.section2} /> */}
        {includesCrossSell && (
          <MWContainer ref={MWRef}>
            <MWSection {...data?.section2} />
          </MWContainer>
        )}
        <OrderSummary {...data?.section3} scrollToMW={scrollToMW} />
      </Container>
    </>
  );
};

export default Success;
